
import { Component, Vue } from "vue-property-decorator";
import { namespace } from "vuex-class";
import selectedClient from "../components/customer-filter.vue";
import noticeChannel from "../components/notice-channel.vue";
import { Table } from "@/utils/decorator";
import t from "@common/src/i18n/t";
const base = namespace("base");
const coupon = namespace("coupon");
const campaign = namespace("campaign");
const app = namespace("app");

@Component({
  components: {
    selectedClient,
    noticeChannel
  }
})
@Table()
export default class SendCollectCustomer extends Vue {
  @base.Action getDictionaryList;
  @campaign.Action queryCampaignDetail;
  @campaign.Action createCampaignPush;
  @coupon.Mutation setDialog;
  @app.Mutation setPBPX;
  @base.Action queryStoreMessageBalance;
  t = t;
  //计算属性
  get breadData() {
    return [
      { name: t("v210801.customer-accumulation-activities"), path: "/marketing/campaign/collect-customer" },
      { name: t("v210801.target-customer-push") }
    ];
  }
  defaultChannel: any = ["weixin"];
  tableData = [];
  dialogVisible = true;
  pushForm: any = {
    customerCodeArray: [],
    notifyMethods: []
  };
  get id() {
    return this.$route.params.id;
  }
  created() {
    this.init();
  }
  remove(idx) {
    this.tableData.splice(idx, 1);
    this.customerCodeArr.splice(idx, 1);
    this.customerSum = this.tableData.length || 0;
  }
  handlePushCampaign(type) {
    if (this.tableData && this.tableData.length < 1) {
      return this.$message.error(t("v210831.please-select-a-client"));
    }
    if (this.pushForm.notifyMethods.includes("sms")) {
      // 如果选择了短信
      this.queryStoreMessageBalance().then(data => {
        if (Number(data.data.smsBalance) < this.tableData.length) {
          this.$message.warning(t("v210831.insufficient-sms-balance"));
          return;
        } else {
          console.log("[ 1 ]", 1);
          this.passRight();
        }
      });
    } else {
      this.passRight();
    }
  }
  passRight() {
    this.pushForm.customerCodeArray = this.tableData.map(item => item.customerCode);
    this.createCampaignPush(this.pushForm).then(data => {
      this.$message.success(t("v210831.the-activity-has"));
      this.$router.push("/marketing/campaign/collect-customer");
    });
    // this.$router.push("/marketing/discount-coupon");
  }
  campaignData: any = {
    prize: {}
  };
  init() {
    this.setPBPX(52);
    this.pushForm.campaignCode = this.id;
    this.queryCampaignDetail({ campaignCode: this.id }).then(data => {
      this.campaignData = data.data;
    });
  }
  clickTarget() {
    this.setDialog(true);
  }
  customerSum: number = 0;
  wxSum: number = 0;
  customerCodeArr = [];
  handleReceiveCustomer(customerArr) {
    const filterCustomer = customerArr.filter(item => {
      if (!this.customerCodeArr.includes(item.customerCode)) {
        this.customerCodeArr.push(item.customerCode);
        return item;
      }
    });
    this.tableData.push(...filterCustomer);
    this.customerSum = this.tableData.length || 0;
    this.wxSum = this.tableData.filter(item => item.weixinMiniProgramFlag).length || 0;
  }
}
